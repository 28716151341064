import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchVerticalDetails } from "../../../redux/features/verticalsSlice/verticalDetailsSlice";
import { Block, FormSelect, FormTextField } from "../../../components/Component";
import { verticalId } from "../../../utils/configEnv";
import FormAutoComplete from "../../../components/form/FormAutoComplete";
import { useForm } from "react-hook-form";
import FormPriceInput from "../../../components/form/FormPriceInput";
import FormUpload from "../../../components/form/FormUpload";
import FormMultiSelect from "../../../components/form/FormMultiSelect";
import FormNumberInput from "../../../components/form/FormNumberInput";
import { axios } from "../../../utils/axios";
import { Box, Button } from "@mui/material";
import { addItem, resetItemCreateState } from "../../../redux/features/itemsSlice/itemsSlice";
import { toast } from "react-toastify";
import FormMultiImageUpload from "../../../components/form/FormMultiImageUpload";
import { Spinner } from "reactstrap";

const AddItemForm = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.verticalDetails);

  useEffect(() => {
    dispatch(fetchVerticalDetails(verticalId));

  }, [verticalId]);

  return (
    <>
      <section>
        <Block>
          {/* <BaseFieldFormMapper vertical_id={verticalId} baseform_id={data?.baseformsettings?.id} values={data?.baseformsettings?.tabs} /> */}
          <AddCarFormFields />
        </Block>
      </section>
    </>
  );
};

export default AddItemForm;

function generateVehicleYears(startYear = 1980) {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  return years;
}


const AddCarFormFields = () => {

  const methods = useForm();
  const { handleSubmit, control, watch } = methods;

  const [value, setValue] = useState({});
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  useEffect(() => {
    const subscription = watch((lvalue, { name, type }) => setValue(lvalue));
    return () => subscription.unsubscribe();
  }, [watch]);

  const dispatch = useDispatch();

  const fetchAutocompleteOptions = async (data) => {
    try {
      let url = `/device/api/v1/autocomple/list`;
      return axios.post(url, data).then((response) => {
        return (response.data?.['data'])?.['data'];
      });
    } catch (e) {
      console.error('Error fetching autocomplete options:', e);
      return [];
    }
  };

  const loadOptions = async (inputValue, key, reference) => {
    let query = {
      'query': {
        "key": key,
        "search": inputValue ? inputValue : undefined,
        "referenceValue": reference,
        "referenceKey": reference ? 'CAR_MAKE' : undefined,
        'hasReference': reference ? true : false
      },
      'options': { "pagination": false },
    };
    const options = await fetchAutocompleteOptions(query);
    return options.map((e) => ({ label: e.value, value: e.value }));
  };

  useMemo(() => {
    const fetchdata = async () => {
      let data = await loadOptions('', 'CAR_MAKE')
      setMakes(data)
    }
    fetchdata()
  }, [])
  useMemo(() => {
    const fetchdata = async () => {
      let data = await loadOptions('', 'CAR_MODEL', value['make'])
      setModels(data)
    }
    fetchdata()
  }, [value['make']])

  const {createStatus} = useSelector((state) => state.items)

  useEffect(()=>{
     if(createStatus ==='error'){
      toast.error('Failed to add car')
      dispatch(resetItemCreateState())
    }
  },[createStatus])


  const handelCreateCar=async (data)=>{
    const payload = {
      vertical_id:verticalId,
      ...data
    }
    dispatch(addItem(payload))
    
  }


  return (
    <form onSubmit={handleSubmit(handelCreateCar)}>

      <FormAutoComplete name='make' label='Make' control={control} loadOptions={(v) => loadOptions(v, 'CAR_MAKE')} options={makes} />
      <FormAutoComplete name='model' label='Model' control={control} loadOptions={(v) => loadOptions(v, 'CAR_MODEL', value['make'])} options={models} />
      <FormTextField name='trim' label='Trim' control={control} />
      <FormTextField name='vin' label='VIN' control={control} />
      <FormSelect name='engine' label='Engine' control={control} options={['1.0L','1.5L', '2.0L','3.0L','4.0L']} />
      <FormSelect name='cylinder' label='Cylinder' control={control} options={['1', '2', '3', '4', '5', '6', '8', '12']} />
      <FormSelect name='year' label='Year' control={control} options={generateVehicleYears()} />
      <FormSelect name='fuel' label='Fuel' control={control} options={['Gasoline', 'Diesel', 'CNG', 'Electric']} />
      <FormSelect name='transmission' label='Transmission Type' control={control} options={['Manual', 'Automatic','Semi-Automatic']} />
      <FormSelect name='specs' label='Specs' control={control} options={["GCC",
        "American",
        "Canadian",
        "EU",
        "Japan",
        "Korean",
        "Chinese",
        "Other"]} />
      <FormSelect name='insurance' label='UAE insurance' control={control} options={['Yes', 'No']} />
      <FormSelect name='type' label='Type' control={control} options={['SUV', 'Coupe', 'Sedan', 'Cross Over', 'Hard Top Convertible', 'Soft Top Convertible', 'Pick Up Truck', 'Hatchback', 'Sports Car']} />
      <FormSelect name='drive_train' label='Drive Train' control={control} options={['4WD', 'AWD', '2WD']} />
      <FormNumberInput name='mileage' label='Mileage' unit={'KM'} control={control} options={[]} />
      <FormMultiSelect name='tag' label='Tags' control={control} options={['CLEAN_SLATE', 'RUN_&_DRIVE']} />
      <FormPriceInput name='auction_starting_price' label='Auction Starting Price' control={control} />
      <FormPriceInput name='direct_sale_price' label='Direct Sale Price' control={control} />
      <FormMultiImageUpload name='images' label='Images' control={control} />
      <Box paddingTop={2} justifyContent='flex-end' display='flex'>
        <Button type="submit" variant="contained" >{createStatus === 'loading'?(<Spinner/>): "Create"}</Button>
        </Box>

    </form>
  );
}
