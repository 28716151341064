import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Icon from "../icon/Icon";
import { useDispatch } from "react-redux";
import placeholder from "../../images/benz2.jpg";
import { getImage } from "../../utils/uploadImageToS3";
import { Spinner } from "reactstrap";

// Aviable sizes {xs,sm,md,lg,xl}
/* Aviable theme {blue-dim, blue, azure-dim, azure, indigo-dim, indigo, purple-dim, purple, pink-dim, pink, orange-dim, orange, teal-dim, teal, 
    primary-dim, primary, secondary-dim, secondary, success-dim, success, info-dim, info, warning-dim, warning, danger-dim, danger, dark-dim, dark, gray-dim, gray, lighter, light} */

const Image = ({ className, size, width, image, imageAlt, ...props }) => {
  const classes = classNames({
    [`${className}`]: className,
  });

  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fun() {
      try {
        if (image && image !== "defaults/default_image.png") {
          setLoading(true);
          const obj = await getImage(image);
          if (obj) setImageUrl(obj);
          // console.log(obj);
        }
      } catch (e) { } finally {
        setLoading(false)
      }
    }
    fun();
  }, [image]);
  
  return (
    <div className={classes}>
      {loading ? <Spinner /> :imageUrl? <img
        src={imageUrl ?? "defaults/default_image.png"}
        alt={imageAlt}
        style={{ objectFit:imageUrl? "cover":'contain', objectPosition: "center", height: size, width:imageUrl? width:null  }}
      /> :<></>}
      {props.children}
    </div>
  );
};

export default Image;
