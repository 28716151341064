import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Badge, Spinner, ButtonGroup, Button } from "reactstrap";
import { useDebounce } from "use-debounce";
import {
  fetchAuctions,
  clearAuctions,
  setAllSelected,
  setSelected,
  debouncedFetchAuctions,
} from "../../redux/features/auctionSlice/auctionSlice";
import Content from "../../layout/content/Content";
import ListBlockHeader from "../../components/list-block-header/ListBlockHeader";
import TableComponent from "../../components/table/TableComponent";
import { getAuctionStatus, getAuctionStatusBgColor, getAuctionStatusDotColor } from "../../utils/getColor";
import formatDate from "../../utils/dateFormat";
import { TimerText } from "../../components/timer/timer";
import ReactDatePicker from "react-datepicker";
import { auctionStatus } from "../../utils/Utils";
import msToTime from "../../utils/msToTime";
import FilterSection from "./components/FilterSection";
import EditButtonWithModal from "./components/EditButtonWithModal";

const MyAuctions = () => {

  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    searchText: "",
    status: "",
    type: "",
    dateRange: [null, null],
    currentPage: 1,
    sortByField: "createdAt",
    sortByValue: -1,
  });

  const [debouncedSearchText] = useDebounce(filters.searchText, 500);
  const { data, totalCount, perPage, loading } = useSelector((state) => state.auctions);
  const [searchParams] = useSearchParams();

  const user = useSelector((state)=>state.auth.loggedInUser)


  // Fetch auction data based on filters
  const fetchData = useCallback(() => {
 
      const { currentPage, sortByField, sortByValue, filter, type, dateRange, searchText } = filters;
      const requestData = {
        query: { 
          type:'USER',
          user_id:user?.id,
          auction_type:'TIMED'
        },
        options: {
          page: currentPage,
          limit: perPage,
          sort: sortByValue === 1 ? sortByField : `-${sortByField}`,
        },
      }

      if (filter) requestData.query.filter = filter;
      if (type) requestData.query.type = type;
      if (dateRange[0] && dateRange[1]) {
        requestData.query.date = {
          startDate: dateRange[0].toISOString(),
          endDate: dateRange[1].toISOString(),
        };
      }
      if (searchText.trim()) {
        requestData.query.search = searchText.trim();
        dispatch(debouncedFetchAuctions(requestData));
      } else {
        dispatch(fetchAuctions(requestData));
      }
    
  }, [filters,  dispatch, perPage]);



  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    return () => {
      dispatch(clearAuctions());
    };
  }, [dispatch]);

  // Handle search change
  const handleSearchChange = (e) => {
    setFilters((prev) => ({ ...prev, searchText: e.target.value, currentPage: 1 }));
  };

  // Handle date range change
  const handleDateRangeChange = (range) => {
    setFilters((prev) => ({ ...prev, dateRange: range, currentPage: 1 }));
  };

  // Handle status change
  const handleStatusChange = (e) => {
    setFilters((prev) => ({ ...prev, filter: e.target.value, currentPage: 1 }));
  };

  // Handle pagination
  const handlePageChange = (newPage) => {
    setFilters((prev) => ({ ...prev, currentPage: newPage }));
  };

  // Handle sorting
  const handleSortChange = (field, direction) => {
    setFilters((prev) => ({
      ...prev,
      sortByField: field,
      sortByValue: direction,
      currentPage: 1,
    }));
  };

  // Clear all filters
  const clearFilters = () => {
    setFilters({
      searchText: "",
      filter: "",
      type: "",
      dateRange: [null, null],
      currentPage: 1,
      sortByField: "createdAt",
      sortByValue: -1,
    });
  };

  const handleEdit = ()=>{}

  // Columns for the auction table
  const columns = [
    {
      label: "Title",
      field: "baseform.title",
      sortable: true,
      render: (item) => <Link to={`/auctions/${item.id}`}>{`${item.item?.make} ${item.item?.model} ${item.item?.year}`}</Link>,
    },
    {
      label: "Start Time",
      field: "startTime",
      sortable: true,
      render: (item) => <span>{formatDate(new Date(item.start_time))}</span>,
    },
    {
      label: "Duration",
      field: "duration",
      sortable: true,
      render: (item) => (
        item.status === auctionStatus.LIVE ? (
          <TimerText
            startTime={item.start_time}
            duration={item.duration}
            onTimerEnd={() => console.log("Timer ended")}
          />
        ) : (
          msToTime(item.duration)
        )
      ),
    },
    {
      label: "Price (AED)",
      field: "initial_price",
      sortable: true,
      render: (item) => <span>{item.current_price?.toLocaleString("en-US")}</span>,
    },
    {
      label: "Bid Count",
      field: "bid_count",
      sortable: true,
      render: (item) => <span>{item.bid_count}</span>,
    },
    {
      label: "Status",
      field: "status",
      sortable: true,
      render: (item) => (
        <Badge color={getAuctionStatusBgColor(item.status)}>
          {getAuctionStatus(item.status)}
        </Badge>
      ),
    },
    {
      label: "Actions", // Actions column
      field: "actions", // You can leave this since it's non-data specific
      render: (item) => (item.status === auctionStatus.UPCOMING&&
        <EditButtonWithModal auction={item}  />
          
      ),
    },
  ];

  return (
    <React.Fragment>
      <Content>
        <FilterSection
        filters={filters}
          handleSearchChange={handleSearchChange}
          handleDateRangeChange={handleDateRangeChange}
          clearFilters={clearFilters}
          handleStatusChange={handleStatusChange}
       
        />
         
     

        <TableComponent
          data={data}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={loading}
          sortByField={filters.sortByField}
          sortByValue={filters.sortByValue}
        />
       
      </Content>
    </React.Fragment>
  );
};

export default MyAuctions;
