import React, { useEffect, useState } from 'react'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import { FormRadio, FormSelect, Icon, } from '../../../components/Component'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { createBulkAuctions, resetBulkAuctionCreateState } from '../../../redux/features/itemsSlice/itemsSlice'
import { useForm } from 'react-hook-form'
import { formatDuration } from '../../../utils/formatDuration'
import { fetchVerticalDetails } from '../../../redux/features/verticalsSlice/verticalDetailsSlice'
import { verticalId } from '../../../utils/configEnv'
import FormDateTime from '../../../components/form/FormDateTime'
import FormSwitch from '../../../components/form/FormSwitch'
import { Button } from '@mui/material'



export default function CreateBulkAuctionButton() {

    const { createBulkAuctionStatus,
        createBulkAuctionError,
    } = useSelector((state) => state.items,(a,b)=>a?.items?.createBulkAuctionStatus!== b?.items?.createBulkAuctionStatus)


    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    };


    const methods = useForm({
    });
    const { handleSubmit, control, watch } = methods;

    useEffect(() => {
        const subscription = watch((value, { name, type }) => setValue(value.auctionType));
        return () => subscription.unsubscribe();
    }, [watch]);



    const selectedVertical = useSelector((state) => state.verticalDetails?.data);


    const dispatch = useDispatch();
    useEffect(() => {
        if (!selectedVertical) {
            dispatch(fetchVerticalDetails(verticalId));
        }
    }, [dispatch, selectedVertical]);
    useEffect(() => {
        if (createBulkAuctionStatus === "success") {
            toast.success("Auctions Created Successfully");
            dispatch(resetBulkAuctionCreateState())
            setIsOpen(false);
        } else if (createBulkAuctionStatus === "error") {
            toast.error(`Auctions creating failed, ${createBulkAuctionError}`);
            dispatch(resetBulkAuctionCreateState())

        }
    }, [createBulkAuctionStatus, createBulkAuctionError,dispatch]);

    const handleBulkAuctionCreation = async (data) => {
        // console.log(data)
        dispatch(createBulkAuctions(data));
    };

    return (
        <div>
            <div className="me-3">
                <Button variant='contained' onClick={toggle}>
                    Bulk Auctions
                </Button>
            </div>
            <Modal isOpen={isOpen} >
                <form onSubmit={handleSubmit(handleBulkAuctionCreation)}>
                    <ModalHeader
                        close={
                            <button className="close" onClick={toggle} type="button">
                                <Icon name="cross" />
                            </button>
                        }
                    >
                        Create Bulk Auctions
                    </ModalHeader>
                    <ModalBody>
                        <Col sm="12">
                            <FormRadio
                                name='auctionType'
                                label='AuctionType'
                                options={[{ label: 'Timed Auction', value: 'TIMED' }, { label: 'Live Auction', value: 'LIVE' }]}
                                control={control}
                            />
                        </Col>
                        {/* <Col sm="12">
                            <FormPriceInput
                                control={control}
                                name='reserve_price'
                                label={`Reserve Price (Minimum AED 
                                    ${selectedVertical?.verticalsettings_id?.auctionStartingPrice})`}
                                validate={(v) => {
                                    if (v < selectedVertical?.verticalsettings_id?.auctionStartingPrice) {
                                        return `Must be greater than or equal to ${selectedVertical?.verticalsettings_id?.auctionStartingPrice}`
                                    }
                                    return true
                                }}
                            />
                        </Col>
                        <Col sm="12">
                            <FormPriceInput
                                control={control}
                                name='starting_price'
                                label="Starting Bid (75% of Reserve Price)"
                                disabled

                            />
                        </Col> */}
                        {value === 'LIVE' && <Col sm="12">
                            <FormSelect
                                name='durationPerAuction'
                                label='Duration Per Auction (duration of single item)'
                                options={[60000, 120000]}
                                displayOption={(e) => ({ label: formatDuration(e), value: e })}
                                control={control}
                            />
                        </Col>}
                        <Col sm="12">

                            <FormDateTime
                                name='startTime'
                                label='Start Time'
                                control={control}
                            />
                        </Col>
                        {value === 'TIMED' && <Col sm="12">
                            <FormDateTime
                                name='endTime'
                                label='End Time'
                                control={control}
                            />
                        </Col>}
                        {value === 'LIVE' && <Col sm="12" style={{ paddingTop: 12, paddingBottom: 12 }}>
                            <FormSwitch
                                name='enablePreBidding'
                                label='Enable Pre bidding'
                                control={control}
                            />
                        </Col>}
                        {/* <Col sm="12">
                            <FormSelect
                                name='emirate'
                                label='Emirate'
                                options={Emirates}
                                control={control}
                            />
                        </Col>
                        <Col sm="12">
                            <FormSelect
                                name='transport'
                                label='Transport'
                                options={TransportTypes}
                                control={control}
                            />
                        </Col> */}

                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col>
                                <Button color="light" onClick={toggle} style={{ width: "100%" }} type="button">
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button color="primary" type='submit' style={{ width: "100%" }}>
                                    {createBulkAuctionStatus === 'loading' ? <Spinner size="sm" /> : "Save"}
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </form>
            </Modal>
        </div >
    )
}
