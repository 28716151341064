// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import DealerImagesForm from './components/DealerImagesForm';
// import DealerLocationForm from './components/DealerLocationForm';
// import DealerAccountForm from './components/DealerAccountForm';
// import {
//   fetchDealerDetails,
//   updateDealerDetails
// } from '../../redux/features/dealerSettings/DealerSettingsSlice';
// import Content from '../../layout/content/Content';
// import {
//   BlockBetween,
//   BlockDes,
//   BlockHead,
//   BlockHeadContent,
//   BlockTitle
// } from '../../components/Component';
// import { Badge } from 'reactstrap';
// import { startCase } from 'lodash';
// import { Paper } from '@mui/material';

// export default function SettingsPage() {
//   const dispatch = useDispatch();
//   const dealerDetails = useSelector((state) => state.dealer);

//   useEffect(() => {
//     dispatch(fetchDealerDetails('dealerId')); // Fetch dealer details on mount
//   }, [dispatch]);

//   const handleImagesSubmit = (data) => {
//     dispatch(
//       updateDealerDetails({
//         ...dealerDetails,
//         logoImage: data.logoImage,
//         heroImage: data.heroImage
//       })
//     );
//   };

//   const handleLocationSubmit = (data) => {
//     dispatch(updateDealerDetails({ ...dealerDetails, location: data }));
//   };

//   const handleAccountSubmit = (data) => {
//     dispatch(updateDealerDetails({ ...dealerDetails, accountDetails: data }));
//   };

//   return (
//     <React.Fragment>
//       <Content>
//         <div>
//           <BlockHead size="lg">
//             <BlockBetween>
//               <BlockHeadContent>
//                 <BlockTitle tag="h4">Dealer Details</BlockTitle>
//                 <BlockDes>
//                   <p>Basic information</p>
//                   <Badge>{startCase(dealerDetails?.name)}</Badge>
//                 </BlockDes>
//               </BlockHeadContent>
//             </BlockBetween>
//           </BlockHead>

//           <Paper sx={{padding:4}}>

//           <DealerImagesForm
//             dealerImages={dealerDetails}
//             onSubmit={handleImagesSubmit}
//           />

//           <DealerLocationForm
//             dealerLocation={dealerDetails?.location}
//             onSubmit={handleLocationSubmit}
//           />

//           <DealerAccountForm
//             accountDetails={dealerDetails?.accountDetails}
//             onSubmit={handleAccountSubmit}
//           />
//           </Paper>
//         </div>
//       </Content>
//     </React.Fragment>
//   );
// }
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Button,
  Grid2,
  Stack,
  Box,
  Paper
} from '@mui/material';
import Content from '../../layout/content/Content';
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle
} from '../../components/Component';
import { Badge, Spinner } from 'reactstrap';
import { startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchDealerDetails, resetDealerUpdateStatus, updateDealerDetails } from '../../redux/features/dealerSettings/DealerSettingsSlice';
import DealerImagesForm from './components/DealerImagesForm';
import DealerLocationForm from './components/DealerLocationForm';
import DealerAccountForm from './components/DealerAccountForm';
import { toast } from 'react-toastify';
import DealerDocumentsForm from './components/DealerDocumentsForm';


const SettingsPage = () => {
  const dispatch = useDispatch();
  const {data,updateStatus} = useSelector((state) => state.dealerSettings);

  useEffect(() => {
    dispatch(fetchDealerDetails()); // Fetch dealer details on mount
  }, [dispatch]);
  const [editingSections, setEditingSections] = useState({
    images: false,
    location: false,
    account: false
  });

  useEffect(()=>{
    if(updateStatus === 'success'){
        toast.success('Successfully updated data')
        dispatch(resetDealerUpdateStatus());
        setEditingSections({
            images: false,
            location: false,
            account: false
          });
    }else if(updateStatus === 'error'){
        toast.error('Failed to update data')
        dispatch(resetDealerUpdateStatus());
    }
  },[updateStatus])



  const onSubmitImages = (data) => {
    dispatch(updateDealerDetails({ ...data, }));
  };
  
  const onSubmitLocation = (data) => {
    dispatch(updateDealerDetails({ ...data,  }));
  };
  
  const onSubmitAccount = (data) => {
    dispatch(updateDealerDetails({  account: data }));
  };
  const onSubmitDocuments = (data) => {
    dispatch(updateDealerDetails({  documents: data }));
  };


  const toggleEdit = (sectionName) => {
    setEditingSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName]
    }));
  };
  if(data == null){
    return <Spinner/>
  }

  return (
    <React.Fragment>
      <Content>
        <Paper sx={{ padding: 4 }}>
          <div style={{ padding: '16px' }}>
            <BlockHead size="lg">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle tag="h4">Dealer Details</BlockTitle>
                  <BlockDes>
                    <p>Basic information</p>
                    <Badge>{startCase(data?.name)}</Badge>
                  </BlockDes>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <DealerImagesForm dealer={data} onSubmit={onSubmitImages} toggleEdit={toggleEdit} editMode={editingSections['images']}/>
            <DealerLocationForm dealer={data} onSubmit={onSubmitLocation} toggleEdit={toggleEdit} editMode={editingSections['location']}/>
            <DealerAccountForm dealer={data} onSubmit={onSubmitAccount} toggleEdit={toggleEdit} editMode={editingSections['account']}/>
            <DealerDocumentsForm dealer={data} onSubmit={onSubmitDocuments} toggleEdit={toggleEdit} editMode={editingSections['documents']}/>
          </div>
        </Paper>
      </Content>
    </React.Fragment>
  );
};

export default SettingsPage;
