import React from "react";
import { Controller } from "react-hook-form";
import uuid from "react-uuid";
import { motion } from "framer-motion";
import { Upload, X } from "lucide-react";
import RenderPreview from "./RenderUploadPreview";
import './FormMultiImageUpload.css'

export default function FormMultiImageUpload({ control, name, label, ...props }) {
  const {
    maxFilesLength = 5,
    minFilesLength = 1,
    inputType = "image",
    active = "false",
    dragenter = () => null,
    dragleave = () => null,
    drop = () => null,
    handleFileChange = () => null,
    removeImage = (ind) => null,
    errorMessage = "",
    multiple = true,
    inputProps,
  } = props;

  const inputId = uuid();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className="upload-container">
          <label className="upload-label">{label}</label>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`upload-area ${
              active === "true"
                ? "upload-active"
                : active === "bad"
                ? "upload-error"
                : "upload-default"
            }`}
            onDragEnter={dragenter}
            onDragLeave={dragleave}
            onDrop={drop}
            onDragOver={dragenter}
          >
            <input
              type="file"
              name="fileupload"
              id={inputId}
              onChange={(e) => {
                const files = Array.from(e.target.files);
                const updatedFiles = multiple
                  ? [...(field.value || []), ...files].slice(0, maxFilesLength)
                  : files;
                field.onChange(updatedFiles);
                handleFileChange(e);
              }}
              className="upload-input"
              {...inputProps}
              multiple={multiple}
              accept="image/*"
              hidden
            />

            <div className="upload-preview-container">
              {field?.value?.length >0? (
                field.value.map((file, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.3 }}
                    className="upload-preview"
                  >
                    <RenderPreview file={file} onRemove={()=>{
                       const updatedFiles = field.value.filter((_, i) => i !== index);
                       field.onChange(updatedFiles);
                       removeImage(index);
                    }}/>
                  
                  </motion.div>
                ))
              ) : (
                <label htmlFor={inputId} className="upload-label-content">
                  <Upload size={40} className="upload-icon" />
                  <span className="upload-text">Drag and drop or click to upload</span>
                  <span className="upload-subtext">(Max {maxFilesLength} files)</span>
                </label>
              )}
            </div>
            {error && <motion.p className="upload-error-text">{error.message}</motion.p>}
            {field.value?.length !== 0 && field.value?.length < maxFilesLength && (
              <motion.label
                htmlFor={inputId}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="upload-add-more"
              >
                Add more files
              </motion.label>
            )}
          </motion.div>
        </div>
      )}
    />
  );
}
