import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select/async";
import { axios } from "../../utils/axios";

export default function FormAutoComplete({
  name,
  control,
  label,
  loadOptions,
  options,
  referenceValue,
  displayOption,
  transformer,
  onSelect,
  ...props
}) {
  // Default display option function if not provided
  const defaultDisplayOption = (option) => ({ label: option, value: option });

  // Use provided displayOption function or fall back to default
  const getOptionDisplay = displayOption || defaultDisplayOption;

  // Default transformer function if not provided
  const defaultTransformer = (value) => value;

  // Use provided transformer function or fall back to default
  const transformValue = transformer || defaultTransformer;

//   const fetchAutocompleteOptions = async (data) => {
//     try {
//       let url = `/device/api/v1/autocomple/list`;
//       return axios.post(url, data).then((response) => {
//         return (response.data?.['data'])?.['data'];
//       });
//     } catch (e) {
//       console.error('Error fetching autocomplete options:', e);
//       return [];
//     }
//   };

//   const loadOptions = async (inputValue) => {
//     let query = {
//       'query': {
//         "key": field.key,
//         "search": inputValue,
//         "hasReference": field.hasReference,
//         "referenceKey": field.referenceKey,
//         "referenceValue": referenceValue,
//         "referenceValueMulti": field.referenceValueMulti,
//         "referenceValueType": field.referenceValueType ?? 'single'
//       },
//       'options': { "pagination": false },
//     };
//     const options = await fetchAutocompleteOptions(query);
//     return options.map(getOptionDisplay);
//   };

//   useEffect(() => {
//     if (referenceValue) {
//       onSelect(null); // Reset selection when referenceValue changes
//     }
//   }, [referenceValue, onSelect]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div style={{ width: "100%" }}>
          <label>{label}</label>
          <ReactSelect
            cacheOptions
            loadOptions={loadOptions}
            value={field.value && getOptionDisplay(field.value)}
            defaultOptions={options}
            onChange={(selectedOption) => {
              const transformedValue = transformValue(selectedOption.value);
              field.onChange(transformedValue);
              onSelect(selectedOption);
            }}
            {...props}
          />
          {error && <div className="text-red-500 my-1">{error.message}</div>}
        </div>
      )}
    />
  );
}
