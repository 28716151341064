import React, { useEffect, useState } from 'react';
import { RSelect } from '../../../components/Component';
import { Col, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addAuction, resetAddAuctionStatus } from '../../../redux/features/auctionSlice/auctionDetailsSlice';
import { toast } from 'react-toastify';
import { auctionStatus, Emirates, TransportTypes } from '../../../utils/Utils';
import { fetchVerticalDetails } from '../../../redux/features/verticalsSlice/verticalDetailsSlice';
import { formatDuration, parseDurationString } from '../../../utils/formatDuration';
import { Button } from '@mui/material';
import { verticalId } from '../../../utils/configEnv';

export default function AddAuctionModal({ item }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState(null);
  const [startTime, setStartTime] = useState(new Date());

  const minAllowedTime = new Date();
  minAllowedTime.setMinutes(minAllowedTime.getMinutes() + 10);

  const methods = useForm({
    defaultValues: {
      duration: '',
      startTime: Date.now(),
      initialPrice: ''
    }
  });

  const selectedVertical = useSelector((state) => state.verticalDetails?.data);
  const { addAuctionStatus, addAuctionError } = useSelector((state) => state.auctionDetails);

  useEffect(() => {
    if (!selectedVertical) {
      dispatch(fetchVerticalDetails(verticalId));
    }
  }, [dispatch, selectedVertical]);

  const formattedDurations = ['5 Mins','10 Mins','3 Hrs','1 Day','2 Days'];

  const handleDateChange = (date) => {
    setStartTime(date);
  };

  const addNewAuction = (e) => {
    e.preventDefault();
    setLoading(true);

    let time = new Date(startTime);
    const minAllowedTime = new Date();
    minAllowedTime.setMinutes(minAllowedTime.getMinutes() + 9);

    if (time < minAllowedTime) {
      toast.error('Please select a time that is at least 10 minutes in the future.');
      setLoading(false);
      return;
    }

    let data = {
      duration: parseDurationString(duration),
      type: 'PUBLIC',
      start_time: time.getTime(),
      item_id: item?.id,
      vertical_id: verticalId,
    };

    dispatch(addAuction(data));
  };

  useEffect(() => {
    if (addAuctionStatus === 'success') {
      toast.success('Auction scheduled successfully');
      setLoading(false);
      setOpen(false);
      dispatch(resetAddAuctionStatus());
    } else if (addAuctionStatus === 'error') {
      toast.error('Error: ' + addAuctionError);
      setLoading(false);
      dispatch(resetAddAuctionStatus());
    }
  }, [addAuctionStatus, addAuctionError, dispatch]);

  const toggleModal = () => {
    setOpen(!open);
  };

  const isFormValid = duration && startTime;

  return (
    <>
      <Button
        disabled={item.status === 'AUCTION_SCHEDULED' || item.status === 'AUCTION_LIVE'}
        variant='contained'
        sx={{ margin: 2 }}
        onClick={() => setOpen(true)}
      >
        Start Auction
      </Button>
      <Modal size="md" isOpen={open} toggle={toggleModal} backdrop="static">
        <ModalHeader toggle={toggleModal}>
          Add New Auction
          <button className="close" onClick={toggleModal}>×</button>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={(e) => addNewAuction(e)}>
            <Col sm="12">
              <label className="form-label" htmlFor="start-time">
                Start Time
              </label>
              <DatePicker
                className="form-control"
                minDate={new Date()}
                dateFormat="MMMM d, yyyy h:mm aa"
                showTimeInput
                selected={startTime}
                onChange={handleDateChange}
                style={{ width: '100%' }}
              />
            </Col>

            <Col sm="12">
              <div className="form-group mb-2">
                <label className="form-label" htmlFor="duration">
                  Duration
                </label>
                <div className="form-control-wrap">
                  <RSelect
                    options={formattedDurations?.map((e) => ({
                      label: e,
                      value: e
                    }))}
                    onChange={(e) => setDuration(e.value)}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </Col>

            <Col sm="6">
              <Button
                type="submit"
                className="bg-primary text-white mt-2"
                disabled={!isFormValid || loading}
              >
                {loading ? <Spinner size="sm" color="light" /> : 'Submit'}
              </Button>
            </Col>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
