import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid2 } from "@mui/material";
import { toast } from "react-toastify";

import { axios } from "../../../utils/axios";
import FormAutoComplete from "../../../components/form/FormAutoComplete";
import { FormSelect, FormTextField } from "../../../components/Component";
import FormNumberInput from "../../../components/form/FormNumberInput";
import FormMultiSelect from "../../../components/form/FormMultiSelect";
import FormPriceInput from "../../../components/form/FormPriceInput";
import FormMultiImageUpload from "../../../components/form/FormMultiImageUpload";
import { resetItemEditState, updateItem } from "../../../redux/features/itemsSlice/itemDetailsSlice";



const EditCarModal = ({ isOpen, handleClose, carDetails }) => {
  const dispatch = useDispatch();
  const { editItemStatus,editItemError } = useSelector((state) => state.carDetails);  // Assuming you're using this slice for item updates

  const methods = useForm({
    defaultValues: carDetails || {}  // Default to car details if editing
  });
  const { handleSubmit, control, watch, } = methods;
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [value, setValueState] = useState({});

  useEffect(() => {
    const subscription = watch((lvalue) => setValueState(lvalue));
    return () => subscription.unsubscribe();
  }, [watch]);

  // Load car makes and models for dropdowns
  useEffect(() => {
    async function fetchData() {
      const carMakes = await loadOptions("", "CAR_MAKE");
      setMakes(carMakes);
      if (carDetails?.make) {
        const carModels = await loadOptions("", "CAR_MODEL", carDetails.make);
        setModels(carModels);
      }
    }
    fetchData();
  }, [carDetails]);

  const fetchAutocompleteOptions = async (data) => {
    try {
      let url = `/device/api/v1/autocomple/list`;
      return axios.post(url, data).then((response) => {
        return (response.data?.['data'])?.['data'];
      });
    } catch (e) {
      console.error('Error fetching autocomplete options:', e);
      return [];
    }
  };

  const loadOptions = async (inputValue, key, reference) => {
    const options = await fetchAutocompleteOptions({
      query: { key, search: inputValue, referenceValue: reference, referenceKey: reference ? 'CAR_MAKE' : undefined },
      options: { pagination: false }
    });
    return options.map((e) => ({ label: e.value, value: e.value }));
  };

  const handleEditCar = async (data) => {
    const payload = Object.keys(data).reduce((acc, key) => {
        if (data[key] !== carDetails[key]) {
          acc[key] = data[key];
        }
        return acc;
      }, {});
    dispatch(updateItem({id:carDetails.id,data:payload}));

    if (editItemStatus === "success") {
      toast.success("Car details updated successfully!");
      handleClose();  // Close modal after successful update
     dispatch( resetItemEditState())
    } else if (editItemStatus === "error") {
      toast.error(`Error: ${editItemError ??"Failed to update car details"}`);
     dispatch( resetItemEditState())

    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Car Details</DialogTitle>
      <form onSubmit={handleSubmit(handleEditCar)}>
      <DialogContent
          dividers
          sx={{
            maxHeight: '70vh',  // Adjust height for the content area
            overflowY: 'auto',  // Enable scroll when content overflows
          }}
        >
          <Grid2 container spacing={3}>
            {/* First column */}
            <Grid2 item size={6}>
              <FormAutoComplete name='make' label='Make' control={control} loadOptions={(v) => loadOptions(v, 'CAR_MAKE')} options={makes} />
            </Grid2>
            <Grid2 item size={6}>
              <FormAutoComplete name='model' label='Model' control={control} loadOptions={(v) => loadOptions(v, 'CAR_MODEL', value['make'])} options={models} />
            </Grid2>
            <Grid2 item size={6}>
              <FormTextField name='trim' label='Trim' control={control} />
            </Grid2>
            <Grid2 item size={6}>
              <FormTextField name='vin' label='VIN' control={control} />
            </Grid2>
            <Grid2 item size={6}>
              <FormSelect name='engine' label='Engine' control={control} options={['1.0L','1.5L', '2.0L','3.0L','4.0L']} />
            </Grid2>
            <Grid2 item size={6}>
              <FormSelect name='cylinder' label='Cylinder' control={control} options={['1', '2', '3', '4', '5', '6', '8', '12']} />
            </Grid2>
            <Grid2 item size={6}>
              <FormSelect name='year' label='Year' control={control} options={generateVehicleYears()} />
            </Grid2>
            <Grid2 item size={6}>
              <FormSelect name='fuel' label='Fuel' control={control} options={['Gasoline', 'Diesel', 'CNG', 'Electric']} />
            </Grid2>
            <Grid2 item size={6}>
              <FormSelect name='transmission' label='Transmission Type' control={control} options={['Manual', 'Automatic','Semi-Automatic']} />
            </Grid2>
            <Grid2 item size={6}>
              <FormSelect name='specs' label='Specs' control={control} options={['GCC', 'American', 'Canadian', 'EU', 'Japan', 'Korean', 'Chinese', 'Other']} />
            </Grid2>
            <Grid2 item size={6}>
              <FormSelect name='insurance' label='UAE insurance' control={control} options={['Yes', 'No']} />
            </Grid2>
            <Grid2 item size={6}>
              <FormSelect name='type' label='Type' control={control} options={['SUV', 'Coupe', 'Sedan', 'Cross Over', 'Hard Top Convertible', 'Soft Top Convertible', 'Pick Up Truck', 'Hatchback', 'Sports Car']} />
            </Grid2>
            <Grid2 item size={6}>
              <FormSelect name='drive_train' label='Drive Train' control={control} options={['4WD', 'AWD', '2WD']} />
            </Grid2>
            <Grid2 item size={6}>
              <FormNumberInput name='mileage' label='Mileage' unit={'KM'} control={control} />
            </Grid2>
            <Grid2 item size={6}>
              <FormMultiSelect name='tag' label='Tags' control={control} options={['CLEAN_SLATE', 'RUN_&_DRIVE']} />
            </Grid2>
            <Grid2 item size={6}>
              <FormPriceInput name='auction_starting_price' label='Auction Starting Price' control={control} />
            </Grid2>
            <Grid2 item size={6}>
              <FormPriceInput name='direct_sale_price' label='Direct Sale Price' control={control} />
            </Grid2>
            <Grid2 item size={12}>
              <FormMultiImageUpload name='images' label='Images' control={control} />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            {editItemStatus === 'loading' ? "Saving..." : "Save Changes"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCarModal;

function generateVehicleYears(startYear = 1980) {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }
  return years;
}
