import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Card,
  Box,
  CircularProgress,
  Checkbox
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addItemsToGroup, fetchAddableItemsToGroup } from '../../../redux/features/auctionSlice/addItemsToGroupSlice';
import { Image } from '../../../components/Component';
import CylinderIcon from './CylinderIcon';
import MeterIcon from './MeterIcon';
import { List, AutoSizer, InfiniteLoader } from 'react-virtualized';

const ITEM_HEIGHT = 150; // Fixed height for each item

const ItemCard = ({ item, style, isSelected, onToggleSelect }) => {
  if (!item) return null;
  
  return (
    <div style={style}>
      <Card 
        elevation={1} 
        sx={{ 
          padding: 2, 
          width: '100%', 
          height: ITEM_HEIGHT - 16, 
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: isSelected ? 'action.selected' : 'background.paper'
        }}
      >
        <Checkbox
          checked={isSelected}
          onChange={() => onToggleSelect(item.id)}
          sx={{ mr: 2 }}
        />
        <Stack direction="row" spacing={2} flex={1}>
          <Box
            borderRadius={2}
            height={100}
            width={100}
            border="hidden"
            overflow="hidden"
          >
            <Image image={item.title_image} size={100} width={100} />
          </Box>
          <Stack justifyContent="center" spacing={0.5}>
            <Typography variant="subtitle2" fontWeight="bold">
              {item.make} {item.model}
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography color="gray" variant="body1" fontSize={12}>
                Starting Price:
              </Typography>
              <Typography
                variant="body1"
                color="primary"
                fontSize={12}
                fontWeight="bold"
              >
                AED {item.auction_starting_price}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CylinderIcon sx={{ fontSize: 20 }} />
                <Typography fontSize={9}>{item?.cylinder ?? 4} CYL</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <MeterIcon sx={{ fontSize: 20 }} />
                <Typography fontSize={9}>{item?.Mileage ?? 4} Km</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </div>
  );
};

export default function AddItemToGroupButtonWithModal({id}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const dispatch = useDispatch();
  const { data,status, totalCount } = useSelector((state) => state.addableItems);

  if(status === 'loading' && !loading){
    setLoading(true)
  }

  const loadMoreItems = ({ startIndex, stopIndex }) => {
    setLoading(true);
    const page = Math.floor(startIndex / 20) + 1;
    return dispatch(fetchAddableItemsToGroup({
      query: { filter: 'NOT_LISTED' },
      options: { perPage: 20, page }
    })).then(() => {
      setLoading(false);
    });
  };



  const handleOpen = () => {
    dispatch(fetchAddableItemsToGroup({
        query: { filter: 'NOT_LISTED' },
        options: { perPage: 20, page: 1 }
      }));
      return setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedItems(new Set());
  };

  const handleAdd = () => {
    const selectedIds = Array.from(selectedItems);
    console.log('Selected item IDs:', selectedIds);
    dispatch(addItemsToGroup({id,items:selectedIds}))
    handleClose();
  };

  const isItemLoaded = ({ index }) => index < data.length;

  const handleToggleSelect = (itemId) => {
    setSelectedItems(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(itemId)) {
        newSelected.delete(itemId);
      } else {
        newSelected.add(itemId);
      }
      return newSelected;
    });
  };

  const rowRenderer = ({ key, index, style }) => {
    return (
      <div key={key} style={style}>
        {index < data.length ? (
          <ItemCard 
            item={data[index]} 
            isSelected={selectedItems.has(data[index].id)}
            onToggleSelect={handleToggleSelect}
          />
        ) : (
          loading && <CircularProgress size={24} />
        )}
      </div>
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          fontSize: '16px',
          padding: '10px 40px',
          borderRadius: '25px',
          textTransform: 'none',
          boxShadow: '0px 4px 6px rgba(0, 123, 255, 0.1)',
          '&:hover': {
            backgroundColor: 'primary.dark',
            boxShadow: '0px 6px 8px rgba(0, 123, 255, 0.3)'
          }
        }}
      >
        Add Items
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-items-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="add-items-dialog-title">Add Items</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Please select the items to add to the auction group.
          </Typography>
          <Box height={800}>
            <AutoSizer>
              {({ width, height }) => (
                <InfiniteLoader
                  isRowLoaded={isItemLoaded}
                  loadMoreRows={loadMoreItems}
                  rowCount={data?.length || 0}
                >
                  {({ onRowsRendered, registerChild }) => (
                    <List
                      ref={registerChild}
                      height={height}
                      onRowsRendered={onRowsRendered}
                      rowCount={data?.length || 0}
                      rowHeight={ITEM_HEIGHT}
                      rowRenderer={rowRenderer}
                      width={width}
                    />
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button 
            onClick={handleAdd} 
            color="primary" 
            variant="contained"
            disabled={selectedItems.size === 0}
          >
            Add {selectedItems.size} Items
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}