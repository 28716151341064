import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { debounce } from "lodash";
import store from "../../store";

const initialState = {
  data: [],
  totalCount: 0,
  perPage: 10,
  loading: false,
  error: null,
};
const fetchMyAuctions = createAsyncThunk("auctions", (data) => {

  let url = `/device/api/v1/auction/list`;
  return axios.post(url, data).then((response) => {
    return response.data;
  });

});
const clearAuctions = createAsyncThunk('clearAuctions', async () => {
  return null;
});
const myAuctionsSlice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const { index, value } = action.payload;
      // console.log(index);
      let data = state.data;
      data[index].check = value;
      state.data = data;
    },
    setAllSelected: (state, action) => {
      const { value } = action.payload;
      // console.log(value);
      let data = state.data;
      state.data = data.map((e) => {
        e.check = value;
        return e;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMyAuctions.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = [];
    });
    builder.addCase(fetchMyAuctions.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.data = action.payload?.data;
      state.totalCount = action.payload?.data?.paginator?.itemCount;
      state.perPage = action.payload?.data?.paginator?.perPage;
    });
    builder.addCase(fetchMyAuctions.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(clearAuctions.fulfilled, (state) => {
      state.data = {};
    });
  },
});

const debounced = debounce(({ ...arg }, dispatch) => dispatch(fetchMyAuctions({ ...arg })), 500);
const debouncedFetchMyAuctions =
  ({ ...args }) =>
    (dispatch) =>
      debounced({ ...args }, dispatch);

export default myAuctionsSlice.reducer;
export const { setSelected, setAllSelected } = myAuctionsSlice.actions;
export { fetchMyAuctions, debouncedFetchMyAuctions, clearAuctions };
