import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid2,
  Chip,
  Tabs,
  Tab,
  styled,
  Stack
} from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Search, Clear, FilterList } from '@mui/icons-material';
import DateRangePickerCustom from '../../../components/date-filter/DateRangePicker';
import CreateBulkAuctionButton from '../../cars/components/CreateBulkAuctionButton';
import CreateBulkDirectSaleButton from '../../cars/components/CreateBulkDirectSaleButton';

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: '#1073C0',
    backgroundColor: '#fff',
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: '#1073C0'
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
  })
);

const FilterSection = ({
  filters,
  handleSearchChange,
  handleDateRangeChange,
  handleStatusChange,
  clearFilters,
  selectedRows
}) => {
  const statusOptions = [
    { value: '', label: 'All' },
    { value: 'NOT_LISTED', label: 'Not Listed' },
    { value: 'AUCTION', label: 'In Auction' },
    { value: 'DIRECT_SALE', label: 'In Direct Sale' },
    { value: 'SOLD', label: 'Sold' },
    { value: 'UNSOLD', label: 'Unsold' }
  ];

  const handleTabChange = (event, newValue) => {
    handleStatusChange({ target: { value: newValue } });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ mb: 3 }}>
        <Grid2
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          {/* Header aligned to the left */}
          <Grid2 item xs={12} md={6} lg={6} sx={{ textAlign: 'left' }}>
            <Typography variant="h5" component="h3" gutterBottom>
              All Inventory
            </Typography>
          </Grid2>

          {/* Filters aligned to the right */}
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 item xs={12} md={6} lg={6} sx={{ textAlign: 'right' }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end', // Align filters to the right
                  alignItems: 'center' // Vertically center the elements
                }}
              >
                <TextField
                  size="small"
                  placeholder="Search title"
                  value={filters?.searchText}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <Search
                        fontSize="small"
                        sx={{ color: 'text.secondary', mr: 1 }}
                      />
                    )
                  }}
                  sx={{ flexGrow: 1, maxWidth: 200 }}
                />

                {/* Date Range Picker */}
                <DateRangePickerCustom
                  filters={filters}
                  handleDateRangeChange={handleDateRangeChange}
                />

                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<Clear />}
                  onClick={clearFilters}
                  sx={{ whiteSpace: 'nowrap' }} // Prevent text wrap
                >
                  Clear Filters
                </Button>
              </Box>
            </Grid2>
          </Grid2>
        </Grid2>
        <Stack direction="row" justifyContent="space-between" marginTop={2}>
          {/* Active Filters Section */}
          <>
            {(filters?.searchText || filters?.dateRange || filters?.status) && (
              <Box
                sx={{ mt: 2, display: 'flex', gap: 1, alignItems: 'center' }}
              >
                <FilterList fontSize="small" sx={{ color: 'text.secondary' }} />
                <Typography variant="body2" sx={{ mr: 1 }}>
                  Active filters:
                </Typography>

                {filters.searchText && (
                  <Chip
                    label={`Search: ${filters.searchText}`}
                    onDelete={() =>
                      handleSearchChange({ target: { value: '' } })
                    }
                    size="small"
                  />
                )}
                {(filters.dateRange[0] || filters.dateRange[1]) && (
                  <Chip
                    label={`Date: ${
                      filters.dateRange[0]?.toLocaleDateString() ?? ''
                    } - ${filters.dateRange[1]?.toLocaleDateString() ?? ''}`}
                    onDelete={() => handleDateRangeChange([null, null])}
                    size="small"
                  />
                )}
              </Box>
            )}
          </>
          <Stack direction="row">
            <div
              style={{ display: selectedRows?.length > 0 ? 'block' : 'none' }}
            >
              <CreateBulkAuctionButton />
            </div> 
            {/* <div
              style={{ display: selectedRows?.length > 0 ? 'block' : 'none' }}
            >
              <CreateBulkDirectSaleButton/>
            </div> */}
          </Stack>
        </Stack>
        {/* Status Tabs */}
        <Box width={'100%'} sx={{ marginTop: 2 }}>
          <Tabs
            value={filters.status}
            onChange={handleTabChange}
            sx={{
              borderRadius: '8px',
              backgroundColor: 'Background'
            }}
            TabIndicatorProps={{
              sx: {
                display: 'none' // Hide the default indicator
              }
            }}
          >
            {statusOptions.map((option) => (
              <StyledTab
                key={option.value}
                value={option.value}
                label={option.label}

                // sx={{
                //
                // }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default FilterSection;
