import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { getImage } from "../../utils/uploadImageToS3";
import { Button, Col, Icon, Row, UserAvatar } from "../Component";
import Slider from "react-slick";
import { SlickArrowLeft, SlickArrowRight } from "../partials/slick/SlickComponents";
import { Container, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import placeHolderImage from "../../images/product/a.png";
import { useRef } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { resetImageUpdate, updateImages } from "../../redux/features/itemsSlice/imagesSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { gsap } from "gsap";

const Carousal = ({ images, id }) => {

  console.log(images?.length)
  const [imageUrls, setImageUrls] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isOpenFull, setIsOpenFull] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fullViewImage, setFullViewimage] = useState(null);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const toggleFull = () => {
    setIsOpenFull(!isOpenFull);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    if (slider1 === null) setNav1(slider1.current);
    if (slider2 === null) setNav2(slider2.current);
  }, [slider1, slider2]);

  useEffect(() => {
    let isMounted = true;
    async function getImages() {
      let urls = [];
      for (let i = 0; i < (images?.length ?? 0); i++) {
        const k = images[i];
        if (k && k !== "defaults/default_image.png") {
          const obj = await getImage(k);
          if (obj) urls.push({ key: k.key, image: obj, url: k });
        }
      }
      if (isMounted) setImageUrls(urls);
    }
    getImages();
    return () => {
      isMounted = false;
    };
  }, [images]);
  const sliderSettings = {
    className: "slider-init row",
    slidesToShow: 6,
    slidesToScroll: 4,
    centerMode: false,
    infinite: false,
    swipeToSlide: true,
    draggable: true,
    arrows: false,
    responsive: [
      { breakpoint: 3000, settings: { slidesToShow: 4 } },
      { breakpoint: 1540, settings: { slidesToShow: 3 } },
      { breakpoint: 1340, settings: { slidesToShow: 2 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 576, settings: { slidesToShow: 1 } },
    ],
  };
  const mainSliderSettings = {
    className: "slider-init ",
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };
  console.log(imageUrls)
  return (
    <Box position="relative" minHeight={'100%'} width={'100%'} className="carousal-large-image">
      <Slider
        initialSlide={activeStep}
        {...mainSliderSettings}
        asNavFor={nav2}
        ref={(slider) => setNav1(slider)}
        infinite={false}
        afterChange={(index) => handleStepChange(index)}
        style={{
          display: "block",
          overflow: "clip",
          objectPosition: "center",
          position: "absolute",
          top: 0,
          bottom: 50,
          width: "100%",
        }}
      >
        {imageUrls?.map((step, index) => (
          <div key={index} style={{ minHeight: "100%", objectPosition: "center" }}>
            <img
              onClick={() => {
                setFullViewimage(step);
                toggleFull();
              }}
              style={{
                height: "36rem",
                minWidth: "100%",
                minHeight: "100%",
                overflow: "clip",
                objectPosition: "center",
                objectFit: "cover",
                background: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif",
              }}
              src={step.image}
            />
          </div>
        ))}
      </Slider>

      {imageUrls.length > 0 && (
        <div style={{ right: 100, left: 100, bottom: 0, height: 100, position: "absolute" }}>
          <Slider {...sliderSettings} asNavFor={nav1} ref={(slider) => setNav2(slider)} focusOnSelect={true}>
            {imageUrls?.map((step, index) => (
              <div key={index}>
                <Box
                  component="img"
                  src={step.image}
                  sx={{
                    width: "100px",
                    height: "100px",
                    overflow: "clip",
                    objectPosition: "center",
                    objectFit: "cover",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      opacity: [0.9, 0.8, 0.7],
                    },
                    border: 2,
                    borderColor: activeStep === index ? "primary.main" : "",
                    borderRadius: 2,
                    background: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif",
                  }}
                  onClick={() => handleStepChange(index)}
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
      {/* <div
        className="show-on-hover-over-carousal user-avatar bg-lighter"
        onClick={toggle}
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon name="pen2" />
      </div> */}
      {isOpen && (
        <EditModel
          isOpen={isOpen}
          toggle={toggle}
          imageUrls={imageUrls}
          id={id}
          setIsOpen={setIsOpen}
          style={{ backgroundColor: "000FF" }}
        />
      )}
      {isOpenFull && (
        <Modal modalClassName="modallikkkk" isOpen={isOpenFull} toggle={toggleFull} size="xl">
          <div style={{ width: "100%", height: "100%", objectPosition: "center" }}>
            <img src={fullViewImage.image} style={{ objectFit: "fill", height: "100%", width: "100%" }} />
          </div>
        </Modal>
      )}
    </Box>
  );
};

export default Carousal;

const EditModel = ({ isOpen, toggle, imageUrls, id, setIsOpen }) => {
  const [carImages, setCarImages] = useState([]);
  const [front, setFront] = useState(null);
  const [rear, setRear] = useState(null);
  const [right, setRight] = useState(null);
  const [left, setLeft] = useState(null);
  const [interiorFront, setInteriorFront] = useState(null);
  const [interiorRear, setInteriorRear] = useState(null);
  const [additionalimages, setAdditionalimages] = useState([]);
  const [trashBin, setTrashBin] = useState([]);
  useEffect(() => {
    setCarImages(imageUrls?.filter((e) => e.key === "carImages"));
    setAdditionalimages(imageUrls?.filter((e) => e.key === "inspectionImages"));
    setFront(imageUrls?.find((e) => e.key === "carFrontImage"));
    setRear(imageUrls?.find((e) => e.key === "carRearImage"));
    setRight(imageUrls?.find((e) => e.key === "carRightImage"));
    setLeft(imageUrls?.find((e) => e.key === "carLeftImage"));
    setInteriorFront(imageUrls?.find((e) => e.key === "carInteriorFrontImage"));
    setInteriorRear(imageUrls?.find((e) => e.key === "carInteriorRearImage"));
  }, [imageUrls]);
  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const drag = (ev, image) => {
    ev.dataTransfer.setData("object", JSON.stringify(image));
    ev.dataTransfer.setData("launch", ev.target.parentNode.id);
  };
  const dropInCarImages = (ev) => {
    try {
      ev.preventDefault();
      var launch = ev.dataTransfer.getData("launch");
      var object = ev.dataTransfer.getData("object");
      object = JSON.parse(object);

      if (ev.target.id !== launch && ev.target.parentNode.id !== launch) {
        switch (launch) {
          case "car-images":
            setCarImages(carImages.filter((e) => e.url !== object.url));
            break;
          case "car-inspection-images":
            setAdditionalimages(additionalimages.filter((e) => e.url !== object.url));
            break;
          case "car-front-image":
            setFront(null);
            break;
          case "car-rear-image":
            setRear(null);
            break;
          case "car-right-image":
            setRight(null);
            break;
          case "car-left-image":
            setLeft(null);
            break;
          case "car-interior-front-image":
            setInteriorFront(null);
            break;
          case "car-interior-rear-image":
            setInteriorRear(null);
            break;
          case "drop-table":
            setTrashBin(trashBin?.filter((e) => e.image !== object.image));
            break;
        }
        var id = ev.target.id;
        if (ev.target.localName === "img") id = ev.target.parentNode.id;
        switch (id) {
          case "car-images":
            object.key = "carImages";
            setCarImages([...carImages, object]);
            ev.target = "car-images-item";
            break;
          case "car-inspection-images":
            object.key = "inspectionImages";
            setAdditionalimages([...additionalimages, object]);
            break;
          case "car-front-image":
            object.key = "carFrontImage";
            if (front) setTrashBin([...trashBin, front]);
            setFront(object);
            break;
          case "car-rear-image":
            object.key = "carRearImage";
            if (rear) setTrashBin([...trashBin, rear]);
            setRear(object);
            break;
          case "car-right-image":
            object.key = "carRightImage";
            if (right) setTrashBin([...trashBin, right]);
            setRight(object);
            break;
          case "car-left-image":
            object.key = "carLeftImage";
            if (left) setTrashBin([...trashBin, left]);
            setLeft(object);
            break;
          case "car-interior-front-image":
            object.key = "carInteriorFrontImage";
            if (interiorFront) setTrashBin([...trashBin, interiorFront]);
            setInteriorFront(object);
            break;
          case "car-interior-rear-image":
            object.key = "carInteriorRearImage";
            if (interiorRear) setTrashBin([...trashBin, interiorRear]);
            setInteriorRear(object);
            break;
          case "drop-table":
            object.key = null;
            setTrashBin([...trashBin, object]);
            break;
        }
      } else {
      }
    } catch (e) { }
  };

  const handleDropChange = (acceptedFiles) => {
    setTrashBin([
      ...trashBin,
      ...acceptedFiles.map((file) => {
        // console.log(file);
        return {
          image: URL.createObjectURL(file),
          name: file.name,
          type: file.type,
        };
      }),
    ]);
  };
  const { updateStatus, error } = useSelector((state) => state.updateImages);

  useEffect(() => {
    if (updateStatus === "success") {
      toast.success("Images Updated Successfully");
      dispatch(resetImageUpdate());
      setIsOpen(false);
    } else if (updateStatus === "error") {
      toast.error(error);
      dispatch(resetImageUpdate());
    }
  }, [updateStatus]);

  const dispatch = useDispatch();
  const save = () => {
    const images = [];
    images.push(...carImages);
    if (front) images.push(front);
    if (rear) images.push(rear);
    if (right) images.push(right);
    if (left) images.push(left);
    if (interiorFront) images.push(interiorFront);
    if (interiorRear) images.push(interiorRear);
    images.push(...additionalimages);

    dispatch(updateImages({ id: id, body: images }));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <Icon name="cross" />
          </button>
        }
      >
        Edit Images
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  //className="dropzone upload-zone dz-clickable"
                  style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                >
                  <input {...getInputProps()} />
                  <div
                    id="drop-table"
                    className="rounded border"
                    onDrop={dropInCarImages}
                    onDragOver={allowDrop}
                    style={{
                      minHeight: "100px",
                      backgroundColor: "aliceblue",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      columnGap: "1rem",
                      rowGap: "1rem",
                      padding: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {trashBin?.map((e, i) => (
                      <img
                        className="image-item"
                        key={i}
                        id={e.image}
                        src={e.image}
                        draggable="true"
                        onDragStart={(ev) => drag(ev, e)}
                        style={{ objectFit: "fill", width: "100px", height: "100px", borderRadius: "4px" }}
                      />
                    ))}
                  </div>
                  <Button color="primary">Upload</Button>
                </div>
              )}
            </Dropzone>
          </div>
          <div className="mt-2">
            Car Images
            <div
              id="car-images"
              className="rounded border mt-1"
              onDrop={dropInCarImages}
              onDragOver={allowDrop}
              style={{
                minHeight: "100px",
                backgroundColor: "aliceblue",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                columnGap: "1rem",
                rowGap: "1rem",
                padding: "1rem",
              }}
            >
              {carImages.map((e, i) => (
                <img
                  key={i}
                  id={e.image}
                  src={e.image}
                  draggable="true"
                  onDragStart={(ev) => drag(ev, e)}
                  style={{ objectFit: "fill", width: "100px", height: "100px", borderRadius: "4px" }}
                />
              ))}
            </div>
          </div>
          <Row className="pt-2">
            <Col md="2">
              Front
              <div
                id="car-front-image"
                className="rounded border"
                onDrop={dropInCarImages}
                onDragOver={allowDrop}
                style={{ height: "100px", backgroundColor: "aliceblue" }}
              >
                {front && (
                  <img
                    key={front.image}
                    id={front.image}
                    src={front.image}
                    draggable="true"
                    onDragStart={(ev) => drag(ev, front)}
                    style={{ objectFit: "fill", width: "100px", height: "100px", borderRadius: "4px" }}
                  />
                )}
              </div>
            </Col>
            <Col md="2">
              Rear
              <div
                id="car-rear-image"
                className="rounded border"
                onDrop={dropInCarImages}
                onDragOver={allowDrop}
                style={{ height: "100px", backgroundColor: "aliceblue" }}
              >
                {rear && (
                  <img
                    key={rear.image}
                    id={rear.image}
                    src={rear.image}
                    draggable="true"
                    onDragStart={(ev) => drag(ev, rear)}
                    style={{ objectFit: "fill", width: "100px", height: "100px", borderRadius: "4px" }}
                  />
                )}
              </div>
            </Col>
            <Col md="2">
              Right
              <div
                id="car-right-image"
                className="rounded border"
                onDrop={dropInCarImages}
                onDragOver={allowDrop}
                style={{ height: "100px", backgroundColor: "aliceblue" }}
              >
                {right && (
                  <img
                    key={right.image}
                    id={right.image}
                    src={right.image}
                    draggable="true"
                    onDragStart={(ev) => drag(ev, right)}
                    style={{ objectFit: "fill", width: "100px", height: "100px", borderRadius: "4px" }}
                  />
                )}
              </div>
            </Col>
            <Col md="2">
              Left
              <div
                id="car-left-image"
                className="rounded border"
                onDrop={dropInCarImages}
                onDragOver={allowDrop}
                style={{ height: "100px", backgroundColor: "aliceblue" }}
              >
                {left && (
                  <img
                    key={left.image}
                    id={left.image}
                    src={left.image}
                    draggable="true"
                    onDragStart={(ev) => drag(ev, left)}
                    style={{ objectFit: "fill", width: "100px", height: "100px", borderRadius: "4px" }}
                  />
                )}
              </div>
            </Col>
            <Col md="2">
              Interior Front
              <div
                id="car-interior-front-image"
                className="rounded border"
                onDrop={dropInCarImages}
                onDragOver={allowDrop}
                style={{ height: "100px", backgroundColor: "aliceblue" }}
              >
                {interiorFront && (
                  <img
                    key={interiorFront.image}
                    id={interiorFront.image}
                    src={interiorFront.image}
                    draggable="true"
                    onDragStart={(ev) => drag(ev, interiorFront)}
                    style={{ objectFit: "fill", width: "100px", height: "100px", borderRadius: "4px" }}
                  />
                )}
              </div>
            </Col>
            <Col md="2">
              Interior Rear
              <div
                id="car-interior-rear-image"
                className="rounded border"
                onDrop={dropInCarImages}
                onDragOver={allowDrop}
                style={{ height: "100px", backgroundColor: "aliceblue" }}
              >
                {interiorRear && (
                  <img
                    key={interiorRear.image}
                    id={interiorRear.image}
                    src={interiorRear.image}
                    draggable="true"
                    onDragStart={(ev) => drag(ev, interiorRear)}
                    style={{ objectFit: "fill", width: "100px", height: "100px", borderRadius: "4px" }}
                  />
                )}
              </div>
            </Col>
          </Row>
          <div className="mt-2">
            Additional Images
            <div
              id="car-inspection-images"
              className="rounded border mt-1"
              onDrop={dropInCarImages}
              onDragOver={allowDrop}
              style={{
                minHeight: "100px",
                backgroundColor: "aliceblue",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                columnGap: "1rem",
                rowGap: "1rem",
                padding: "1rem",
              }}
            >
              {additionalimages.map((e, i) => (
                <img
                  key={i}
                  id={e.image}
                  src={e.image}
                  draggable="true"
                  onDragStart={(ev) => drag(ev, e)}
                  style={{ objectFit: "fill", width: "100px", height: "100px", borderRadius: "4px" }}
                />
              ))}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={save}>
          {updateStatus === "loading" ? <Spinner size="sm" /> : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
