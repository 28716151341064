import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    Button,
    Typography,
    Modal,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    DialogActions,
    Dialog,
    Slide,
    DialogTitle,
    DialogContent,
    Stack
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { completeAuction, getSuccessfulBids, resetSelectWinner, resetSuccessfulBids, selectAuctionWinner } from '../../../redux/features/auctionSlice/auctionGroupDetailsSlice';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Context } from '../auctionGroupDetails';
import { Spinner } from 'reactstrap';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 2,
    p: 4,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectWinnerModalButton() {
    const [open, setOpen] = useState(false);
    const [selectedBidder, setSelectedBidder] = useState(null);
    const dispatch = useDispatch();

    const {data, successfulBids, selectAuctionWinnerStatus,  } = useSelector((state)=>state.auctionGroupDetails);
    const auction = data?.current_auction

    const handleOpen = () => {
        dispatch(completeAuction({ id: auction?.id }))
        dispatch(getSuccessfulBids(auction?.id))
        return setOpen(true);
    };

    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (selectAuctionWinnerStatus === 'success') {
         
            handleClose();
        }
    }, [selectAuctionWinnerStatus]);
   

    const handleSelectWinner = () => {
        if (selectedBidder) {
            dispatch(selectAuctionWinner({ id: auction?.id, body: { auction_id: auction?.id, bid_id: selectedBidder } }))   
        } else {
            toast.error('Please select a winner');
        }
    };



    const userBids = successfulBids?.userBids?.map((bidWrapper) => bidWrapper.bid) ?? [];
    const proBids = successfulBids?.proBids?.map((bidWrapper) => bidWrapper.bid) ?? [];

    // Merge both arrays (proBids and userBids)
    const allBids = [...proBids, ...userBids];

    // Sort the merged array by `amount` (or you can choose another key, e.g., `time`)
    const sortedBids = allBids.sort((a, b) => b.amount - a.amount);




    return (
        <>
        { auction &&
        <div>
            <Button disabled={!auction?.current_bid} variant="outlined" color="success" onClick={handleOpen} sx={{
                backgroundColor: 'primary', // Blue background color
                color: 'primary',              // White text color
                fontSize: '16px',            // Slightly larger font size
                padding: '10px 40px',        // Padding for larger button
                borderRadius: '25px',        // Rounded corners
                textTransform: 'none',       // Disable uppercase text
                boxShadow: '0px 4px 6px rgba(0, 123, 255, 0.1)', // Subtle shadow
                '&:hover': {
                    backgroundColor: 'primary', // Darker blue on hover
                    boxShadow: '0px 6px 8px rgba(0, 123, 255, 0.3)', // Slightly stronger shadow on hover
                },
            }}>
                Sell And Continue
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="select-winner-description"
            >
                <DialogTitle>Select the Auction Winner</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                        Please select one of the bidders below as the winner of the auction.
                    </Typography>
                    <RadioGroup

                        aria-label="auction-winner"
                        name="auction-winner"
                        value={selectedBidder}
                        onChange={(e) => setSelectedBidder(e.target.value)}
                        sx={{ width: 1 }}
                    >
                        {sortedBids?.map((bid) => (
                            <FormControlLabel
                                sx={{ width: 1 }}
                                key={bid._id}
                                value={bid._id}
                                control={<Radio />}
                                label={
                                    <Stack direction='row' spacing={2}  >
                                        <Typography>{bid.user.full_name}</Typography>
                                        <Typography fontWeight="bold" color="primary">
                                            AED {bid.amount}
                                        </Typography>
                                    </Stack>
                                }
                            />
                        ))}

                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={handleSelectWinner} color="primary">
                        {selectAuctionWinnerStatus === 'loading' ? <Spinner size="sm" /> : " Confirm Winner"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>}
        </>
    );
}
