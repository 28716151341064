import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import io from "socket.io-client";
import { apiUrl, verticalId } from "../../../utils/configEnv";
import { store } from "../../store";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { setCar } from "../itemsSlice/itemDetailsSlice";

const initialState = {
  details: { relatedCars: [] },
  watchers: [],
  bids: [],
  currentBid: null,
  totalCount: 0,
  bidsCount: 0,
  loading: false,
  error: null,
  firestoreSubscription: null,
  bidRejected: null,
  placeBidStatus: "init",
  placeBidError: null,
  addAuctionStatus: "init",
  addAuctionError: null,
};

const fetchAuctionDetails = createAsyncThunk("auctions/details", (id) => {
  let url = `/device/api/v1/auction/${id}`;
  return axios.get(url).then((response) => {
    return response.data;
  });
});

const addAuction = createAsyncThunk("addAuction", async (data, { dispatch, rejectWithValue, }) => {
  let url = '/device/api/v1/dealer-auction/create';
  try {
    const response = await axios.post(url, data);
    if (response?.status === 200) {
      dispatch(setCar(response.data?.data?.item))
      return response.data;
    } else {
      return rejectWithValue(response?.data?.message)
    }
  } catch (e) {
    return rejectWithValue(e?.response?.data?.message ?? 'Unknown Error happened')
  }

});

const updateAuctionDetails = createAsyncThunk("auctions/update", async (data) => {
  const { id, body } = data;
  let url = `/device/api/v1/auction/update/${id}`;
  try {
    const response = await axios.put(url, body);
    toast.success('Auctions Updated');
    window.location.reload();
    return response.data;
  } catch (error) {
    throw new Error(error.response.data?.message); // Throw an error with the message
  }
});


const fetchBids = createAsyncThunk("auctions/bids", (data, { dispatch }) => {
  let url = `/device/api/v1/bid/list`;
  return axios.post(url, data).then((response) => {
    dispatch(connectToFirestore(data.query.auction_id))
    return response?.data?.data;
  });
});


const connectToFirestore = createAsyncThunk("auctions/socket", async (id, { getState, dispatch }) => {
  const docRef = doc(db, 'verticals', verticalId, 'auctions', id);
  const unsubscribe = onSnapshot(docRef, (snapshot) => {
    if (snapshot.exists()) {
      const docData = snapshot.data();
      console.log(docData)
      if (docData.event_type === 'BID_ADDED') {
        dispatch(onNewBid(docData.current_bid))
        if (docData.current_bid?.extended) {
          dispatch(onTimeExtended(docData.current_bid.duration))
        }
      }
    } else {
      console.log('Document does not exist.');
    }
  });
  return unsubscribe;
});


const placeBid = createAsyncThunk("auctions/placeBid", (amount, { getState }) => {
  const state = getState()
  const auctionId = state.auctionDetails.details.id;
  const data = {
    user_id: state.auth.loggedInUser.id,
    auction_id: auctionId,
    amount: state.auctionDetails.currentBid + Number(amount),
  };
  return axios.post(`/device/api/v1/bid/create/`, data).then((response) => response.data);
});


const auctionDetailsSlice = createSlice({
  name: "auctionDetails",
  initialState,
  reducers: {
    onReposted: (state, action) => {
      state.details = action.payload;
    },
    onNewBid: (state, action) => {
      if (state.bids?.[0]?.id !== action.payload?.id) {
        state.bids.unshift(action.payload);
        state.currentBid = action.payload?.amount
        state.details.current_bid = action.payload
      }
    },
    onTimeExtended: (state, action) => {
      state.details.duration = action.payload
    },
    onWatcherJoined: (state, actions) => {
      state.watchers.push(actions.payload);
    },
    onWatcherLeft: (state, actions) => {
      const index = state.watchers.indexOf(actions.payload);
      if (index !== -1) state.watchers.splice(index, 1);
    },
    closeSocket: (state) => {
      if (state.socket) {
        const unsubscribe = state.firestoreSubscription;
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
        state.firestoreSubscription = null;
      }
    },
    resetBidRejected: (state) => {
      state.bidRejected = null;
    },
    resetPlaceBid: (state) => {
      state.placeBidStatus = "init";
      state.placeBidError = null;
    },
    setAuctionDetails: (state, actions) => {
      state.details = actions.payload;
      state.currentBid = actions.payload?.current_bid?.amount ?? actions.payload?.item?.auction_starting_price
    },
    resetAddAuctionStatus: (state) => {
      state.addAuctionStatus = 'init';  // reset to initial state
      state.addAuctionError = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuctionDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.details = {};
    });
    builder.addCase(fetchAuctionDetails.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.details = action.payload?.data;
      // state.bids = action.payload?.data?.bids ?? [];
    });
    builder.addCase(fetchAuctionDetails.rejected, (state, action) => {
      state.loading = false;
      state.details = {};
      state.error = action.error.message;
    });
    // socket
    builder.addCase(connectToFirestore.pending, (state) => {
      if (state.socket) {
        state.socket?.close();
        state.socket = null;
      }
    });
    builder.addCase(connectToFirestore.fulfilled, (state, action) => {
      state.firestoreSubscription = action.payload;
    });
    builder.addCase(connectToFirestore.rejected, (state, action) => { });
    //placebid
    builder.addCase(placeBid.pending, (state) => { });
    builder.addCase(placeBid.fulfilled, (state, action) => {
      state.placeBidStatus = "success";

    });
    builder.addCase(placeBid.rejected, (state, action) => {
      // console.log(action.error.message);
      state.placeBidStatus = "error";
      state.placeBidError = action.error.message;
    });
    builder.addCase(fetchBids.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchBids.fulfilled, (state, action) => {
      state.loading = false;
      state.bids = action.payload?.data ?? [];
      state.bidsCount = action.payload?.paginator?.itemCount;
    });
    builder.addCase(fetchBids.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateAuctionDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateAuctionDetails.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.details = action.payload?.data ?? [];
    });
    builder.addCase(updateAuctionDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addAuction.pending, (state) => {
      state.addAuctionStatus = 'loading';
      state.addAuctionError = null;
    });
    builder.addCase(addAuction.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.addAuctionStatus = 'success';
      state.details = action.payload?.data ?? [];
    });
    builder.addCase(addAuction.rejected, (state, action) => {
      state.addAuctionStatus = 'error';
      state.addAuctionError = action.payload;
    });
  },
});

export default auctionDetailsSlice.reducer;
export const { resetBidRejected, closeSocket, resetPlaceBid, onNewBid, setAuctionDetails, onTimeExtended, resetAddAuctionStatus } = auctionDetailsSlice.actions;
export { fetchAuctionDetails, connectToFirestore, placeBid, fetchBids, updateAuctionDetails, addAuction, };
