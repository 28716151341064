import { Dialog, DialogContent, DialogTitle, Typography, Box, Grid2, DialogActions, Button, Container, Divider, Card, CardContent, Paper } from '@mui/material'
import React from 'react'
import Price from '../../../components/price/Price';
import { Edit, Info, Warning } from '@mui/icons-material';
import { markAsSold } from '../../../redux/features/ordersSlice/orderUpdateSlice';
import { useDispatch } from 'react-redux';

function MarkAsSold({ order, open, onClose, onAddTransactionClicked }) {

    const handleDialogClose = (e) => {
        onClose();
    }
const dispatch = useDispatch()
    const onSubmit = (e) => {
       dispatch( markAsSold(order?.id))
        onClose();
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h5" sx={{ fontWeight: 'bold', }}>
                        Mark as sold
                    </Typography>
                    <Typography variant="body" sx={{ fontWeight: 'w500', fontSize: 14, color: "gray" }}>
                        Are you sure you want to mark it as sold
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box>

                        {Number(order.balanceAmount) > 0 && (
                            <Grid2 item xs={4} display={"flex"}>

                                <Container style={{ backgroundColor: "crimson", padding: "8px 8px", borderRadius: 12, display: "flex" }}>

                                    <Info sx={{ fontSize: 22, color: 'white', }} />
                                    <Typography variant="body" sx={{ fontWeight: 'bold', }}>
                                        This order have pending payment of AED{order.balanceAmount}
                                    </Typography>
                                    <Typography
                                        onClick={onAddTransactionClicked}
                                        variant="body"
                                        component="a" // Turns it into a link
                                        href="#" // Add the href for the hyperlink
                                        sx={{ fontWeight: 'bold', textDecoration: 'underline', ml: 1, display: 'block', color: "white" }} // Underline and margin-left
                                    >
                                        Add missing transaction
                                    </Typography>
                                </Container>
                            </Grid2>
                        )}
                        <br />
                        <Paper style={{ padding: 12 }}>
                            <Grid2 item xs={12} >
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: 'bold', ml: 1, display: 'block', fontSize: 14 }} // Underline and margin-left
                                    >
                                        User Info:
                                    </Typography>
                                    <Edit sx={{ fontSize: 22, color: 'gray', }} />
                                </Box>

                            </Grid2>

                            <Typography
                                variant='body'
                                sx={{ fontWeight: 'w400', ml: 1, display: 'block', fontSize: 16 }} >
                                Name:  {(order?.user?.full_name ?? order?.user?.guest_name) ?? ""}

                            </Typography>
                            <Typography
                                variant='body'
                                sx={{ fontWeight: 'w400', ml: 1, display: 'block', fontSize: 16 }} >
                                Phone:  {((order?.user?.phone.dial_code + " " + order?.user?.phone.number) ?? order?.user?.guest_phone) ?? ""}

                            </Typography>

                        </Paper>
                        <br /><br />
                        <Box>
                            <Grid2 container spacing={2}>
                                <Grid2 item xs={4}>
                                    <Price label={"Total Amount:"} amount={Math.round(Number(order.amount))} />
                                </Grid2>
                                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                                <Grid2 item xs={4}>
                                    <Price label={"Received Amount:"} amount={Math.round(Number(order.amount) - Number(order.balanceAmount))} />

                                </Grid2>
                                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                                <Grid2 item xs={4}>
                                    <Price label={"Balance Amount:"} amount={Math.round(Number(order.balanceAmount))} />

                                </Grid2>
                            </Grid2>

                        </Box>
                        <br /> <br />
                        <Grid2>
                            <Container style={{ color: "teal", backgroundColor: "aliceblue", padding: "8px 8px" }}>
                                Note: On Marking as sold you agree to sell the vehicle to the user. Please ensure you received the complete payment before you mark as sold as this action cannot be undone
                            </Container>
                        </Grid2>
                    </Box>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={onSubmit} autoFocus>
                        Mark as sold
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default MarkAsSold