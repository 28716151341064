import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../../utils/axios";
import { fetchAuctionDetails } from "./auctionDetailsSlice";

const initialState = {
  newId: null,
  status: "init",
  loading: false,
  error: null,
};

const selectAuctionWinner = createAsyncThunk("auctions/selectWinner", (data, { dispatch }) => {
  const { id, body } = data;
  let url = `/device/api/v1/auction/select_winner`;
  console.log(body)
  return axios.post(url, body).then((response) => {
    dispatch(fetchAuctionDetails(id));
    return response.data;
  });
});
const selectWinnerSlice = createSlice({
  name: "selectAuctionWinner",
  initialState,
  reducers: {
    resetSelectWinner: (state) => {
      state.status = "init";
      state.error = null;
      state.newId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectAuctionWinner.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = {};
    });
    builder.addCase(selectAuctionWinner.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.status = "success";
    });
    builder.addCase(selectAuctionWinner.rejected, (state, action) => {
      state.loading = false;
      state.status = "error";
      state.error = action.error.message;
    });
  },
});

export default selectWinnerSlice.reducer;
export const { resetSelectWinner } = selectWinnerSlice.actions;
export { selectAuctionWinner };
