import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios'; // Use your axios utility

const initialState = {
  soldCars: [],
  unsoldCars: [],
  loading: false,
  error: null,
};



// Async thunk to fetch sold cars
 const fetchSoldCars = createAsyncThunk(
  'completedAuctions/fetchSoldCars',
  async (auctionGroupId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/device/api/v1/auction-group/sold-cars/${auctionGroupId}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

// Async thunk to fetch unsold cars
 const fetchUnsoldCars = createAsyncThunk(
  'completedAuctions/fetchUnsoldCars',
  async (auctionGroupId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/device/api/v1/auction-group/unsold-cars/${auctionGroupId}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

// Slice definition
const completedAuctionGroupSlice = createSlice({
  name: 'completedAuctions',
  initialState,
  reducers: {
    resetCompletedAuctions: (state) => {
      state.completedAuctions = [];
      state.soldCars = [];
      state.unsoldCars = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {

    // Handle fetching sold cars
    builder.addCase(fetchSoldCars.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSoldCars.fulfilled, (state, action) => {
      state.loading = false;
      state.soldCars = action.payload.data || [];
    });
    builder.addCase(fetchSoldCars.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || action.error.message;
    });

    // Handle fetching unsold cars
    builder.addCase(fetchUnsoldCars.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUnsoldCars.fulfilled, (state, action) => {
      state.loading = false;
      state.unsoldCars = action.payload.data || [];
    });
    builder.addCase(fetchUnsoldCars.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || action.error.message;
    });
  },
});

export const { resetCompletedAuctions } = completedAuctionGroupSlice.actions;

export default completedAuctionGroupSlice.reducer;

export {
  fetchSoldCars,
  fetchUnsoldCars,
};
